import axiosInstances from '@/libs/axios-instances'

export default function Reclaims() {
  const indexRequest = () => axiosInstances.activities.get('/internalops/reclaim/event').then(res => res)

  const showRequest = id => axiosInstances.activities.get(`/internalops/reclaim/${id}/event`)

  const storeRequest = reclaim => axiosInstances.activities.post('/internalops/reclaim/event', reclaim).then(res => res)

  const approveRequest = id => axiosInstances.activities.post(`/internalops/reclaim/${id}/event/approve`).then(res => res)

  const rejectRequest = id => axiosInstances.activities.post(`/internalops/reclaim/${id}/event/reject`).then(res => res)

  return {
    indexRequest, showRequest, storeRequest, approveRequest, rejectRequest,
  }
}
