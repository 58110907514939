<template>
  <div>
    <div class="text-right mb-1">
      <b-button
        variant="primary"
        :to="{name:'reclaim-organizations-add'}"
      >
        Add Organization
      </b-button>
    </div>
    <b-card>
      <validation-observer #default="{ handleSubmit }">
        <b-form @submit.prevent>
          <custom-vue-select
            id="entity-select"
            label="Select Entity"
            rules="required"
            :value-reducer="option=>option.id"
            text-field="name"
            placeholder="No entity selected"
            :options="entities"
            :selected.sync="reclaim.entityId"
          />
          <div class="text-right">
            <submit-button v-bind="{handleSubmit,submit}" />
          </div>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import SubmitButton from '@/common/components/common/FormInputs/SubmitButton.vue'
import CustomVueSelect from '@/common/components/common/FormInputs/CustomVueSelect.vue'
import Reclaims from '@/common/compositions/Reclaims/ReclaimsAPI'
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default {
  name: 'Home',
  components: {
    SubmitButton,
    CustomVueSelect,
  },
  data() {
    return {
      entities: [],
      reclaim: {},
    }
  },
  setup() {
    const { successfulOperationAlert } = handleAlerts()
    const { storeRequest } = Reclaims()
    return { storeRequest, successfulOperationAlert }
  },
  created() {
    this.getEntities()
  },
  methods: {
    getEntities() {
      this.$portalUsers.get('v1/user-entities', {
        params: {
          status: true,
        },
      }).then(res => {
        this.entities = res.data.data
      })
    },
    submit() {
      const eventId = JSON.parse(localStorage.getItem('claimedResource')).claim_id
      return this.storeRequest({ entityId: this.reclaim.entityId, eventId }).then(() => {
        this.successfulOperationAlert('The event is reclaimed successfully')
      })
    },
  },
}
</script>

<style lang="scss">

</style>
